<template>
  <div class="container" v-loading="loading">
    <div class="col-xs-12 col-sm-12 col-md-12">

      
    </div>

    <div class="row course-part">
        <div class="index-video-part-title col-xs-12 col-sm-12 col-md-12 center">
          招牌课程
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="card index-main-course">
            <router-link :to="{ path: '/course/details', query: { id: '3' } }">
              <img src="images/image1.png" item="程序员面试指南，加速步步高升" alt="程序员面试指南，加速步步高升" class="index-core-image1">
            </router-link>
            <div class="card-body">
              <p class="card-text">
                <small class="text-muted">
                  发布时间：2022-06-07 21:55:16
                </small>
              </p>
              <h5 class="card-title">
                <router-link :to="{ path: '/course/details', query: { id: '3' } }">
                  程序员面试指南，加速步步高升
                </router-link>
              </h5>
              <p class="card-text">
                <small class="text-muted">创意豪 发布</small>
              </p>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-8">
          <div class="block index-course-timeline">
            <el-timeline>
              <el-timeline-item timestamp="目标1" placement="top">
                <el-card>
                  <h4>掌握程序员高效求职的整体理论方法</h4>
                </el-card>
              </el-timeline-item>
              <el-timeline-item timestamp="目标2" placement="top">
                <el-card>
                  <h4>从零书写编程类简历,课程配送简历demo</h4>
                </el-card>
              </el-timeline-item>
              <el-timeline-item timestamp="目标3" placement="top">
                <el-card>
                  <h4>讲解前端和通用后端的常考题目</h4>
                </el-card>
              </el-timeline-item>
              <el-timeline-item timestamp="目标4" placement="top">
                <el-card>
                  <h4>演示有理论方法的模拟面试</h4>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  // name: 'Index',
  props: ["user"],
  data() {
    return {
      sliders: null,
      list: null,
      loading: false,
    };
  },
  created() {
    // this.getPageData()
    // this.getNewCourseList();
  },
  methods: {
    // async getPageData() {
    //   const response = await axios.post('/api/web/page/details/index')
    //   const items = Object.values(response.data.data.content)
    //   this.sliders = items[0]
    //   this.loading = false
    //   // console.log(this.sliders[1])
    // },
    async getNewCourseList() {
      const response = await axios.get("/api/web/course/newList");
      const items = response.data.data.data;
      this.list = items;
      // console.log(this.list)
      this.loading = false;
    },
    async course(id) {
      this.$router.push({ path: `/course/${id}` });
    },
  },
};
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>